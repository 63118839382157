import React, { useState } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ModalNewsletter from './Modal'
import FullScreenModal from '../FullScreenModal'
import { Section } from './style'

type NewsletterProps = {
  page?: string
  category?: string
  subcategory?: string
}

const Newsletter = ({ page, category, subcategory }: NewsletterProps) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState('')
  const [sendDatalayerEvent] = useDataLayer()

  return (
    <>
      <Section className="py-5">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <h2 className="fs-20 lh-25 text-grayscale--500 fw-700">
                Receba nossas análises por e-mail
              </h2>
              <p className="fs-14 lh-17 text-grayscale--500">
                <span className="d-block">
                  Garanta acesso às informações que te ajudarão a potencializar
                </span>{' '}
                seus investimentos.
              </p>
            </div>
            <div className="col-12 col-lg-5">
              <div className="row d-flex align-items-end">
                <div className="col-12 col-md-9 col-lg-12 col-xl-8">
                  <label className="fs-14 lh-17 d-block" htmlFor="e-mail">
                    E-mail
                  </label>
                  <input
                    type="text"
                    placeholder="Digite seu e-mail"
                    id="e-mail"
                    onChange={(event) => setEmail(event?.target.value)}
                    value={email}
                  />
                </div>
                <div className="col-12 col-md-3 col-lg-12 col-xl-4">
                  <button
                    type="button"
                    className="mt-3"
                    onClick={() => {
                      setOpen(true)
                      sendDatalayerEvent({
                        section: 'Receba nossas análises por e-mail',
                        element_action: 'click button',
                        element_name: 'Continuar',
                      })
                    }}
                    disabled={email === ''}
                  >
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <FullScreenModal open={open} setOpen={setOpen} padding>
        <ModalNewsletter
          email={email}
          setEmail={setEmail}
          setOpen={setOpen}
          page={page}
          category={category}
          subCategory={subcategory}
          dataLayer={{
            section: 'Receba nossas análises por e-mail',
            element_name: 'Continuar',
            element_action: 'submit',
            section_name: 'Receba nossas análises por e-mail',
            element_previous: '',
            element_search: '',
          }}
        />
      </FullScreenModal>
    </>
  )
}

export default Newsletter
