import articlesJSON from 'src/assets/data/articles.json'
import categoriesJSON from 'src/assets/data/categories.json'
import subCategoriesJSON from 'src/assets/data/subcategories.json'

export const articleFields = (article) => ({
  categoryId: article.category_id,
  categoryName: article.category_name,
  comments: article.comments,
  createdAt: article.created_at,
  gridImageUrl: article.grid_image_url,
  introduction: article.introduction,
  publishedAt: article.published_at,
  readTime: article.read_time,
  shared: article.shared,
  slug: article.slug,
  tagName: article.tag_name,
  title: article.title,
  updatedAt: article.updated_at,
  subcategoryId: article.subcategory_id,
})

export const SpotlightNewsSmallField = (article) => {
  const category = categoriesJSON.find((item) => item.id === article.categoryId)
  const subCategory = subCategoriesJSON.find((item) => item.id === article.subcategoryId)

  return {
    categoryId: category?.id,
    title: article.title,
    link: `/${category?.slug}/${subCategory?.slug}/${article.slug}`,
    image: article.homeImage || article.gridImage,
    video: article.isVideo,
  }
}

export const NewsField = (article) => {
  const category = categoriesJSON.find((item) => item.id === article.categoryId)
  const subCategory = subCategoriesJSON.find((item) => item.id === article.subcategoryId)

  return {
    categorySlug: category?.slug,
    categoria: category?.name,
    titulo: article.title,
    subTitle: article.introduction,
    image: article.gridImage,
    slug: article.slug,
    subcategorySlug: subCategory?.slug,
    link: !article?.isFromInset
      ? `${category?.slug}/${subCategory?.slug}/${article.slug}`
      : `https://www.inset.com.br/${article.insetCategory}/${article.slug}`,
  }
}

export function returnAllArticlesOrderPublishDate() {
  return Object.values(articlesJSON)
    .reduce((all, item) => [...all, ...item], [])
    .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
}

export default { returnAllArticlesOrderPublishDate }
